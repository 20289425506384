.contact-container {
    padding: 7vh;
    background-color:  rgb(248, 248, 248);
    
}


.contact-header1 {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    
}

.contact-info-main {
    font-family: 'Satoshi', sans-serif;
    display: flex;
    align-items: center;
    padding-right: 8px;
}

#contact-info {
    font-family: 'Satoshi', sans-serif;
    text-align: right;
    min-width: 105px;
    padding-right: 8px;
}

#contact-info1 {
    font-family: 'Satoshi', sans-serif;
    text-align: center;
    min-width: 100px;
    padding-right: 8px;
}

.contact-body {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 60px;
    background-color:  rgb(248, 248, 248);
}


.contact-times {
    line-height: 2;
}


#office-pic {
    border-radius: 10px;
    width: 550px;
}


#tmj_map {
    width: 100%;
}

.contact-pic {
    background-image: url('../images/tmj_map.png');
    background-size: cover;
    padding-bottom: 50vh;
}

.custom-shape-divider-top-1687186481 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1687186481 svg {
    position: relative;
    display: block;
    width: calc(146% + 1.3px);
    height: 42px;
}

.custom-shape-divider-top-1687186481 .shape-fill {
    fill: #FFFFFF;
}


@media only screen and (max-width: 440px) {
    .contact-body {
        display: flex;
        flex-direction: column;
    }

    .contact-container {
        padding: .5vh;
        padding-bottom: 3vh;
        font-size: 16px;
    }
}


@media only screen and (max-width: 1050px) {
    .contact-body {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (min-width: 1250px) {
    #office-pic {
        width: 700px;
    }
}