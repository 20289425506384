.custom-shape-divider-top-1694710534 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    background-color: #04253D;
    
}

.custom-shape-divider-top-1694710534 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 48px;
    margin-top: -1px;
    margin-bottom: -1px;
}

.custom-shape-divider-top-1694710534 .shape-fill {
    fill: white;
}


/* make appointment button above footer */

.make-app-footer3 {
    /* background: linear-gradient(to bottom, rgba(188, 187, 187, 0.181), rgba(255, 255, 255, 0.001));
    padding: 9vh 3vh 3vh 3vh;
    background-color: #04253D;
    background-size: contain;
    border-radius: 10px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15vh;
    background: linear-gradient(to bottom, rgba(28, 92, 255, 0.002), rgba(198, 208, 250, 0.585));

}

.make-app-footer {
    background: linear-gradient(to bottom, rgba(188, 187, 187, 0.181), rgba(255, 255, 255, 0.001));
    padding: 9vh 3vh 3vh 3vh;
    background-color: #04253D;
    background-size: contain;
    /* border-radius: 10px; */
}

.make-app-footer2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15vh;
    text-align: center;
}

/* .html-background {
    background-image: url("../src/images/indian-canyon-golf-resort-02.webp");
    background-size: contain;
    background-repeat: ;
} */

#make-app-footer-title {
    text-align: center;
    font-size: 5vh;
    color: white;
}

#make-app-footer-title2 {
    text-align: center;
    font-size: 5vh;
    color:#0A5EA4;
}

#button-nav1 {
    border-color: black;
    border-spacing: 10px;
    border-radius: 20px;
    padding: 12px;
    background-color: #0A5EA4;
    text-shadow: none;
    color: white;
    width: 85px;
    text-align: center;
}

#button-nav1:hover {
    background-color: #206fb0;
}

.button-nav1 {
    display: flex;
    justify-content: space-around;
    padding: 50px;
}


#logo-name4 {
    font-family: 'Satoshi', sans-serif;
    /* text-shadow: black 1px 0 25px; */
    font-weight: 600;
    font-size: 30px;
}


/* #footer-fix {
    margin-top: -1px;
    margin-bottom: -1px;
} */



/* ________________________________________________________________________________ */

.footer {
    display: flex;
    justify-content: space-between;
    padding: 7vh;
    padding-top: 60px;
    gap: 3rem;
    background-color: #04253D;
    color:white;
}

#logo-subtitle1 {
    font-family: 'Literata', serif;
}

#logo-title-bottom {
    font-family: 'Literata', serif;
    font-weight: 600;
    font-size: 30px;
    padding-bottom: 30px;
}

.google-location {
    padding-top: 20px
}

#bottom-font {
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 200;
    font-size: 20px;
}

.footer-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 85px;
    gap: 15px;
}

@media (max-width: 960px) {
    .footer-contact {
        display: flex;
        flex-direction: column;
        padding-top: 5px;
        gap: 15px;
    }

}

@media only screen and (max-width: 440px) {
    .footer {
        padding: 3vh;
    }

    #logo-name4 {
        font-size: 18px;
    }

    #make-app-footer-title {
        text-align: center;
        font-size: 3vh;
        color: white;
    }
}

#footer-icon {
    width: 35px;
}


.footer-hours-time {
    display: flex;
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 200;
    font-size: 20px;
}

.footer-hours {
   
}


#footer-day {
    min-width: 110px;
    
}

.footer-hours-time2 {
    padding-top: 30px;
}


