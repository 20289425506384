#menu-button2 {
    background-color: orange;
    color: white;
}

#list-item {
    font-size: 20px;
}

#list-item:hover {
    background-color: #0A5EA4;
    color: white;
}

.hamburger {
    color: black;
}
