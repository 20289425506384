.App {
    width: 100vw;
    height: 100vh;
}



.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    background-color: antiquewhite;
    height: 100%;
    width: 400px;
    
}

.SidebarList {
    height: auto;
    padding: 0;
    width: 100%;
}

.SidebarList .row {
    width: 100%;
    height: 60px;
    list-style-type: none;
    margin: 0%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Satoshi', sans-serif;
}

.SidebarList .row:hover {
    cursor: pointer;
    background-color: azure;
}

.SidebarList #active {
    background-color: azure;
}

.row #title {
    flex: 70%;
    display: grid;
    place-items: center;
}

