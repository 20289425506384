.logo-name1 {
    min-width: 200px;
    font-size: 15px;
}

#logo-name1 {
    font-family: 'Satoshi', sans-serif;
    font-weight: 600;
    font-size: 30px;
    color: black;
}

#logo-subtitle1 {
    font-family: 'Satoshi', sans-serif;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 400;
    letter-spacing: 4px;
    color: black;
}

#ourteam1:hover {
    color: black;
    border-bottom: 1px solid black;
    transition: all 0.1s ease-out;
}

#tmj1:hover {
    color: black;
    border-bottom: 1px solid black;
    transition: all 0.1s ease-out;
}
#contact1:hover {
    color: black;
    border-bottom: 1px solid black;
    transition: all 0.1s ease-out;
}


#sleepapnea1:hover {
    color: black;
    border-bottom: 1px solid black;
    transition: all 0.1s ease-out;
}


.header1 {
    background-color: rgb(248, 248, 248);
    background-size: cover;
    background-attachment: fixed;
    padding-bottom: 30px;
    position: relative;
    color: black
}

#ourteam1 {
    min-width: 70px;
    color: black;
}

#tmj1 {
    color: black;
}

#sleepapnea1 {
    min-width: 93px;
    color: black;
}

#contact1 {
    color: black;
}


.nav-sections1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: black;
    padding-top: 20px;
}

.navbar-home2 {
    display: none;
}


/* ____________________________ Mobile __________________________________________ */
@media only screen and (max-width: 440px) {
    
    #logo-name1 {
        font-size: 15px;
        min-width: 100px;
    }

    #logo-subtitle1 {
        font-size: 11px;
        justify-content: flex-start;
        letter-spacing: 1.5px;

    }

    /* .white {
        padding: 3.5vh;
    } */
}


@media only screen and (max-width: 1120px) {

    #header-text {
        /* font-size: 30px; */
    }


    .mobile-header {
        display: flex;
    }
    
    .nav-sections1 {
        display: none;
    }


    /* .container1 {
        display: flex;
        justify-content: space-around;
        padding: 4vh;
        padding-right: 7vh;
        padding-left: 7vh;
        
    } */

  

    .navbar-home2 {
        display: flex;
    }

    .navbar {
        display: flex;
        justify-content: flex-start;
    }

}