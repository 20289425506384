@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&family=Rampart+One&family=Roboto+Mono:wght@100&family=Tenor+Sans&family=Tiro+Devanagari+Sanskrit&family=Titillium+Web:wght@300;400;700&family=Trocchi&display=swap');
@import url("https://api.fontshare.com/v2/css?f[]=satoshi@300&display=swap");
@import url("https://api.fontshare.com/v2/css?f[]=satoshi@700,300,400&f[]=gambetta@400,300,700&f[]=literata@200,400,700,900,300&display=swap");
* {
    margin: 0;
    margin-right: 0px;
}

strong {
    font-weight: 400;
}

h3 {
    font-weight: 400;
}
#button328 {
    margin-bottom: 0;
}
#button-nav {
    border-color: black;
    border-spacing: 10px;
    border-radius: 20px;
    padding: 10px;
    background-color: #04253D;
    text-shadow: none;
    color: white;
}
h5 {
    font-family: 'Satoshi', sans-serif;
    font-weight: 200;
}
h6 {
    font-family: 'Satoshi', sans-serif;
}
.logo-name {
    font-size: 15px;

}
#logo-final {
    width: 25px;
    padding-right: 16px;
    padding-bottom: 18px;
    /* padding-left: 148px; */
}
.mobile-header {
    display: flex;
    align-items: center;
}
#logo-name {
    font-family: 'Satoshi', sans-serif;
    /* text-shadow: black 1px 0 25px; */
    font-weight: 600;
    font-size: 30px;
    
}
#logo-subtitle {
    font-family: 'Satoshi', sans-serif;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 400;
    letter-spacing: 4px;
}
.sleep-apnea {
    display: flex;
}
#border-top {
    border-top: 1px solid white; 
    margin: 10px 0 0 0;
}
a {
    display: flex;
    align-items: center;

    list-style-type: none;
    font-size: 16px;
    font-weight: 200;
    font-family: 'Noto Sans Display', sans-serif;
    color: white;
    text-decoration: none;
    /* text-shadow: black 5px 0 10px; */

}
#ourteam:hover {
    color: white;
    border-bottom: 1px solid #fff;
    transition: all 0.1s ease-out;
}
#tmj-home:hover {
    color: white;
    border-bottom: 1px solid #fff;
    transition: all 0.1s ease-out;
}
#sleepapnea:hover {
    color: white;
    border-bottom: 1px solid #fff;
    transition: all 0.1s ease-out;
}
#contact:hover {
    color: white;
    border-bottom: 1px solid #fff;
    transition: all 0.1s ease-out;
}
#button-nav:hover {
    background-color: #093453;
}

.call-to-action {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vh;
    border-radius: 50px;
    background-color: #04253D;
    max-width: 270px;
    /* padding-top: 15px;
    padding-bottom: 15px; */
    
}

.image {
    width: 330px;
    height: auto;
    border-radius: 8px;
    margin-top: 50px;
}

.overlay {
    border-image: linear-gradient(hsl(400 100% 50% / .15), hsl(40 100% 50% / .1)) fill 1;
}

.header {
    background-color: black;
    background-image: url('./images/gradient-dental.webp');
    background-size: cover;
    background-attachment: fixed;
    padding-bottom: 150px;
    position: relative;
    color: white;
}

#ourteam {
    min-width: 70px;
} 
#sleepapnea {
    min-width: 93px;
}
.container1 {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    /* background: rgba(0, 0, 0, 0.1); */
    padding: 4vh;
    padding-right: 7vh;
    padding-left: 7vh;
    /* border-style: solid; */
}
.contact-header {
    /* display: flex;
    align-items: flex-start; */
    text-align: left;
    margin-left: auto;
    padding-left: 5.5vw;
    padding-right: 30px;
    color: white;
    text-shadow: black 1px 0 10px;

}
.contact-header2 {
    color: white;
    margin-bottom: 1vh;
    text-shadow: black 1px 0 10px;
}
.container2 {
    padding-top: 32vh;
    padding-left: 7vh;
    font-size: 35px;
    font-family: 'Gambetta', serif;

}
#header-text {
    font-weight: 500;
    text-shadow: black 1px 0 25px;
    font-size: 50px;
    font-family: 'Satoshi', sans-serif;
}
#underline {
    /* text-decoration: underline; */
    font-size: 20px;
    font-weight: 500;
    text-shadow: black 1px 0 25px;
    
    /* border-color: black;
    border-spacing: 10px;
    border-radius: 20px;
    padding: 10px;
    background-color: #04253D;
    text-shadow: none;
    color: white; */
}
#underline:hover {
    /* border-bottom: 1px solid #fff; */
    /* border: solid;
    border-color: white;
    transition: all 0.1s ease-out; */
}

#underline-box:hover {
    cursor: pointer;
    border: solid;
    border-color: white;
    transition: all 0.08s ease-out;
}

.nav {
    text-align: right;
    margin-left: auto;
    padding-left: 5.5vw;
    color: white;
}
.nav-sections {
    /* display: flex;
    align-content: center;
    justify-content: center; */
    display: flex;
    align-items: center;
    padding-top: 20px;
    gap: 1rem;
}
.custom-shape-divider-bottom-1687213582 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    margin-bottom: -1px;
}
.custom-shape-divider-bottom-1687213582 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 48px;
}
.custom-shape-divider-bottom-1687213582 .shape-fill {
    fill:  rgb(248, 248, 248);
}
.secondline {
    position: relative;
}
/** For mobile devices **/
@media (max-width: 767px) {
    .custom-shape-divider-bottom-1685623564 svg {
        width: calc(300% + 1.3px);
        height: 133px;
    }
}
.custom-shape-divider-top-1685628784 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    margin-bottom: -1px;
    margin-top: -1px;
}
.custom-shape-divider-top-1685628784 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 25px;
    transform: rotateY(180deg);
}
.custom-shape-divider-top-1685628784 .shape-fill {
    fill: antiquewhite
}
/** For mobile devices **/
@media (max-width: 767px) {
    .custom-shape-divider-top-1685628784 svg {
        width: calc(100% + 1.3px);
        height: 133px;
    }
}

/* ------------------- BODY SECTION STARTS HERE -------------------  */
.body-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15vh 15vh 2vh 15vh;
    font-size: 20px;
    text-align: center;
    background-color:  rgb(248, 248, 248);
    gap: 40px;
}

.text-focus-in {
	-webkit-animation: text-focus-in 1.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: text-focus-in 1.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  
.text-focus-in2 {
	-webkit-animation: text-focus-in .4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: text-focus-in .4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

#card-header {
    font-family: 'Satoshi', sans-serif;
    font-weight: 600;
}

#card-paragraph {
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    font-size: 18px;
}
  

#card1 {
    background-color: white;
}

.cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.cards2 {
    display: flex;
    justify-content: center;
    /* flex-wrap: wrap; */
    gap: 40px;
}

@media only screen and (max-width: 780px) {
    .cards2 {
        flex-wrap: wrap;
    }
}


/* .first > #first {
    scale: .88; opacity: 0;
    
    animation: fade-in-top 1.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation-timeline: view();
    animation-range: contain;
}

.second > #second {
    scale: .88; opacity: 0;
   
    animation: fade-in-top 1.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation-timeline: view();
    animation-range: contain;
} */

/* @keyframes fade-in {
    to { scale: 1; opacity: 1;}
}

@keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  } */

.body-one-words {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8vh;
    background-color:  rgb(248, 248, 248);
}

.body-one-paragraph2 {
    
    background-color:#093453;
    color:  rgb(248, 248, 248);
    padding: 7vh;
    text-align: center;
    font-size: 22px;
    border-radius: 20px;
    width: 90%;
}


#body-one-paragraph {
    font-family: 'Satoshi', sans-serif;
    font-weight: 600;
    color: #0C5EA4;
}

#body-one-paragraph2 {
    /* text-align: left; */
    font-weight: 200;
}

.body-two {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 7vh;
    font-size: 20px;
    text-align: center;
    background-color:  rgb(248, 248, 248);
    gap: 40px;
    background: linear-gradient(to bottom, rgba(28, 92, 255, 0.002), rgba(198, 208, 250, 0.385));
}


.body {
    padding: 0vh 7vh 0vh 7vh;
    background-color: #F8F8F8;
    position: relative;
    background: linear-gradient(to top, rgba(28, 92, 255, 0.002), rgba(198, 208, 250, 0.385));
    /* background-image: url('./images/grey-gradient.webp'); */
    background-size: cover;
   
}
.body1 {
    display: flex;
    flex-direction: row;
    padding-top: 10vh;
}
#body1-words {
    font-size: 7vh;
    padding-bottom: 20px;
    color: #0C5EA4;
}
#body1-words2 {
    line-height:1.6em; margin-bottom: .7em;
    font-weight: 200;
    text-align: center;
}

#body1-words5 {
    line-height:1.6em; margin-bottom: .7em;
    font-weight: 200;
    /* text-align: center; */
}

#sleepapnea5 {
    display: flex;
    flex-direction: row;
    border-color: black;
    border-spacing: 10px;
    border-radius: 30px;
    padding: 20px;
    background-color: #0C5EA4;
    text-shadow: none;
    color: white;
    width: 170px;
}

#sleepapnea6 {
    display: flex;
    flex-direction: row;
    border-color: black;
    border-spacing: 10px;
    border-radius: 30px;
    padding: 20px;
    background-color: #0C5EA4;
    text-shadow: none;
    color: white;
    width: 173px;
}

#sleepapnea5:hover {
    background-color: #1a76c6;
}

#sleepapnea6:hover {
    background-color: #1a76c6;
}

.body2 {
    display: flex;
    padding-top: 15vh;
    padding-bottom: 10vh;
}
.grey {
    background-color: lightgrey;
    padding: 30vh;
}


/* ______________   review section ______________________________________________________________________ */
.what-people-saying {
    padding-top: 60px;
    background-color: #0C5EA4;
    color: white;
    text-align: center;
    margin-bottom: -1px;
}

.what-people-saying > h2 {
    font-size: 5vh;
}

.review-bottom-page {
    display: flex;
    flex-direction: column;
}
.more-reviews {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0C5EA4;
    margin-top: -1px;
    margin-bottom: -1px;
}
#forward-arrow-2 {
    padding-top: 2px;
}
.white {
    display: flex;
    align-items: center;
    background-color: #0C5EA4;
    padding: 7vh;
}


.reviews {
    padding: 5vh;
}

.arrows {
    display: flex;
    justify-content: center;
    background-color: #0A5EA4;
    padding-bottom: 3vh;
    gap: 20px;
}


.slider-test {
    display: flex;
    align-items: center;
    justify-content: center;
}
#arrow-button-back {
    padding: 1.5vh;
    font-size: 30px;
    color: #0A5EA4;
    background-color: white;
    border-radius: 30px;
}
#arrow-button-back:hover {
    /* background-color: rgb(239, 238, 238); */
    cursor: pointer;

}
#arrow-button-forward{
    padding: 1.5vh;
    font-size: 30px;
    color: #0A5EA4;
    background-color: white;
    border-radius: 30px;
}
#arrow-button-forward:hover {
    /* background-color: rgb(239, 238, 238); */
    cursor: pointer;

}
.black {
    background-color: black;
    padding-left: 30vh;
    padding-right: 30vh;
    padding-top: 10vh;
    padding-bottom: 16vh;
    color: white;
}
.custom-shape-divider-bottom-1694709272 {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    margin-top: -1px;
    margin-bottom: -1px;
}
.custom-shape-divider-bottom-1694709272 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 46px;
}
.custom-shape-divider-bottom-1694709272 .shape-fill {
    fill: #0C5EA4;
}
.custom-shape-divider-top-1694710534a {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    background-color: #04253D;
    margin-top: -1px;
    margin-bottom: -1px;
}
.custom-shape-divider-top-1694710534a svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 48px;
}
.custom-shape-divider-top-1694710534a .shape-fill {
    fill: #0C5EA4;
}
#reviews {
    text-align: center;
    font-size: 25px;
    font-family: 'Satoshi', sans-serif;
    font-weight: 200;
    color: white;
}
#more-reviews {
    padding-bottom: 60px;
    padding-left: 7vh;
    padding-right: 7vh;
    display: flex;
    align-items: center;
    color: white;
}
@media only screen and (max-width: 440px) {
    .white {
        padding: 3vh .3vh 3vh .3vh;
    }

    .reviews {
        padding: .3vh;
    }

    #reviews {
        font-size: 18px;
    }
    
    #more-reviews {
        padding-left: 0vh;
        padding-left: 0vh;
    }

    #arrow-button-back {
        padding: 0vh;
    }

    #arrow-button-forward {
        padding: 0vh;
    }
}
/* ______________   review section ______________________________________________________________________ */

.lightblue {
    background-color: antiquewhite;
    padding: 30vh;
}
.thirdline {
    position: relative;
}

#first {
    display: flex;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    min-height: 100%;
    min-width: 100%;
    border-radius: 10px;
    /* animation: fadeIn 10s; */
}
.first {
    max-height: 50%;
    max-width: 50%;
    padding-right: 80px;
}
.first-words {
    max-height: 50%;
    max-width: 50%;
    padding-top: 40px;
    padding-bottom: 40px;
}
#second {
    display: flex;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    min-height: 100%;
    min-width: 100%;
    border-radius: 10px;
}
.second {
    max-width: 50%;
    max-height: 50%;
}
.second-words {
    max-height: 50%;
    max-width: 50%;
    padding-right: 80px;
    padding-top: 40px;
    padding-bottom: 40px;
    
}

#phone {
    font-weight: 300;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding: 6vh;
    padding-top: 60px;
    padding-bottom: 80px;
    gap: 3rem;
    /* background-color: black; */
    color:white;
}



.make-app-footer > h3{
    text-align: center;
    padding-left: 10vh;
    padding-right: 10vh;
    /* color: white; */
    font-weight: 100;
}

.locations-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 3vh;
    padding-bottom: 3vh;
}

#locations-pic {
    /* max-width: 50vh; */
    border-radius: 10px;
}



.locations > ul{
    display: flex;
    flex-direction: column;
    align-items: left;
    color: white;
    font-weight: 100;
    gap: 10px;
    padding: 10vh 0vh 10vh 0vh;
    
}
#logo-title-bottom {
    font-family: 'Literata', serif;
    font-weight: 400;
    font-size: 38px;
    padding-bottom: 30px;
}
#bottom-font {
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 200;
    font-size: 20px;
}
.footer-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 85px;
}
.navbar-home1 {
    display: none;
    align-items: center;
}

.custom-shape-divider-bottom-1736481700 {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1736481700 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 47px;
}

.custom-shape-divider-bottom-1736481700 .shape-fill {
    fill: #0A5EA4;
}


.custom-shape-divider-top-1736481314 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1736481314 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 47px;
}

.custom-shape-divider-top-1736481314 .shape-fill {
    fill: #0A5EA4;
}

/* -------------------------------------- for mobile -------------------------------------- */

@media only screen and (max-width: 440px) {


    #locations-pic {
        width: 380px;
    }

    .make-app-footer2 {
        padding: 3vh !important;
    }

    .make-app-footer3 {
        padding: 3vh !important;
    }

    #make-app-footer-title2 {
        font-size: 25px !important;
    }

    .custom-shape-divider-bottom-1736481700 {
        margin-bottom: -2px;
    }

    .arrows {
        margin-top: -1px;
    }

    #card-header {
        font-size: 25px;
    }
    
    .header {
        background-image: url("./images/tmj_heroimg_mobile.webp");
        padding-bottom: 20vh !important;
        background-size: 400px !important;
    }

    #logo-name {
        font-size: 15px;
        min-width: 200px;
    }

    #logo-subtitle {
        font-size: 11px;
        justify-content: flex-start;
        letter-spacing: 1.5px;

    }

    #logo-final {
        padding-top: 21px;
    }

    .container1 {
        padding: 2vh 3vh 0vh 3vh;
        /* display: flex;
        justify-content: space-between;
        padding: 2vh 7vh 7vh 7vh; */
    }

    #header-text {
        font-size: 4vh;
        text-shadow:black 1px 1px;
    }

    .call-to-action {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1vh;
        border-radius: 50px;
        background-color: #04253D;
        max-width: 200px;
        text-align: center;
        z-index: -3;
    }

    #underline {
        font-size: 15px;
        text-shadow:black 1px 1px;
    }

    .body {
        padding: 3vh;
    }

    .body-two {
        padding: 3vh;
    }

    .body-one-paragraph2 {
        padding: 3vh;
    }

    #body-one-paragraph {
        font-size: 25px !important;
    }

    #body-one-paragraph2 {
        font-size: 20px !important;
    }

    .body-one-words {
        padding: 3vh;
    }



    .container2 {
        padding-left: 3vh;
        padding-right: 3vh;
        padding-bottom: .1vh;
        padding-top: 40vh;
    }

    .header {
        padding-bottom: 13vh;
        background-size: 600px;
    }


    .body-one {
        padding: 3vh;
    }

    .body1 {
        padding: 0vh !important;
    }

    .body2 {
        padding: 0vh !important;
    }

    .first-words {
        padding-right: 0vh !important;
        padding-left: 0vh !important;
    }

    .second-words {
        padding-right: 0vh !important;
        padding-left: 0vh !important;
    }

    #body1-words {
        font-size: 25px !important;
    }

    .what-people-saying {
        font-size: 14px;
    }

    .more-reviews {
        padding-top: 30px;
        padding-left: 40px;
    }

    .make-app-footer {
        background-size: cover !important;
    }
    
}

/* -------------------------------------- for mobile -------------------------------------- */



/* smaller screeens */
@media only screen and (max-width: 765px) {
    
    .body1 {
        flex-direction: column;
    }

    .body2 {
        flex-direction: column !important;
    }

    .first {
        padding-right: 0px !important;
        max-width: 100%;
        max-height: 100%;
    }
    
    .second {
        max-width: 100%;
        max-height: 100%;
        
    }

    .locations-container {
        margin-left: -100px;
        margin-right: -100px;
    }
}


@media only screen and (max-width: 1120px) {
    #logo-name {
        /* font-size: 20px; */
    }

    #logo-subtitle {
        /* font-size: 11px; */
    }

    #header-text {
        /* font-size: 30px; */
    }

    #underline {
        /* font-size: 18px; */
        min-width: 232px;
    }

    .body {

    }

    .white {
        padding: 3vh .3vh 3vh .3vh;
    }

    .reviews {
        padding: .3vh;
    }

    #reviews {
        font-size: 18px;
    }
    
    #more-reviews {
        padding-left: 0vh;
        padding-left: 0vh;
    }

    #arrow-button-back {
        padding: 1.5vh;
    }

    #arrow-button-forward {
        padding: 1.5vh;
    }

    .mobile-header {
        display: flex;
    }
    
    .nav-sections {
        display: none;
    }


    .container1 {
        /* display: flex;
        justify-content: space-around; */
        /* padding: 4vh; */
        /* padding-right: 7vh;
        padding-left: 7vh; */
        
    }

    .footer {
        flex-direction: column;
    }

    #footer2 {
        max-width: 423px;
    }

    .footer-contact {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        padding-top: 85px;
        gap: 15px;
    }

    #body-one-paragraph {
        font-size: 40px;
    }

    .navbar-home1 {
        display: flex;
    }

    .navbar {
        display: flex;
        justify-content: flex-start;
    }


    /* .body1 {
        display: flex;
        flex-direction: column;
        padding: 6%;
        
    } */

    #body1-words {
        font-size: 5vh;
    }

    #first {
        display: flex;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        
    }
    
    .first {
        padding-right: 40px;
    }
    
    .first-words {
        max-height: 100%;
        max-width: 100%;
        padding-top: 20px;
        /* padding-left: 50px; */
    }

    .body2 {
        display: flex;
        flex-direction: row-reverse;

    }

    #second {
        display: flex;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        min-height: 100%;
        min-width: 100%;
        
    }
    
    .second {
        /* max-width: 100%;
        max-height: 100%; */
        
    }
    
    .second-words {
        max-height: 100%;
        max-width: 100%;
        padding-right: 40px;
        /* padding-left: 50px; */
        order: 2;
        padding-top: 20px;
       
    }

}



@media only screen and (max-height: 500px) {
.header {
    background-size: 1000px;
}


}

/* --------------------------------- for bigger screens --------------------------------- */




@media only screen and (min-width: 1135px) {
    .header {
        padding-bottom: 450px;
    }

    #body-one-paragraph {
        max-width: 150vh;
        text-align: center;
        align-items: center;
    }

    .body-one-paragraph2 {
        max-width: 96vh;
        text-align: center;
        align-items: center;
    }

    #make-app-footer-title2 {
        max-width: 150vh;
    }

    #body1-words2 {
        max-width: 96vh;
    }

    .container2 {
        padding-top: 55vh;
    }

    #header-text {
        font-size: 60px;
    }

    #underline {
        font-weight: 500;
    }
}

@media only screen and (min-width: 1350px) {
    .header {
        padding-bottom: 650px;
    }
}

/* --------------------------------- for bigger screens --------------------------------- */

