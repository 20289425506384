.navbar {
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
    visibility: hidden;
  }

  .menu-bars {
    margin-right: 2rem;
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    background-color: black;
    width: 250px;
    height: 620px;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 9999;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: flex-start;
  }
  
  .nav-text a:hover {
    background-color: white;
    color: black;

  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    background-color: black;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }


  @media only screen and (max-width: 1120px){
    .navbar {
        height: 69px;
        display: flex;
        justify-content: start;
        align-items: center;
        visibility: visible;
      }
    
}