input {
    border-radius: 10px;
    padding-left: 14px;
    max-width: 200px;
    height: 40px;
}

.personal {
    display: flex;
    flex-direction: column;
    width: 500px;
    gap: 20px;
}

#name {
    background-color: white;
    color: black;
}

.body-appointment {
    padding: 7vh;
    padding-top: 50px;
    background-color: #04253D;
    /* background-color: #3D9CD3; */
    position: relative;
    color: white;
    margin-bottom: -1px;
}

.body-header {
    display: flex;
    padding-bottom: 40px;

}

.body-header1 {
    width: 500px;
    padding-right: 50px;
}

#body-header1 {
    padding-bottom: 20px;
}

.body4 {
    display: flex;
    flex-direction: column;
    width: 500px;
    gap: 20px;
}


#title {
    padding-top: 4vh;
    padding-bottom: 4vh;
}

#subtitle {
    text-align: right;
    min-width: 135px;
    font-weight: 600;
    color: white;
}

.preferred {
    display: flex;
    /* align-items: center; */
    gap: 20px;
    
}

.custom-shape-divider-bottom-1686862665 {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    margin-bottom: -1px;
}

.custom-shape-divider-bottom-1686862665 svg {
    position: relative;
    display: block;
    width: calc(115% + 1.3px);
    height: 36px;
}

.custom-shape-divider-bottom-1686862665 .shape-fill {
    fill: #04253D;
}

.preferred2 {
    display: flex;
    margin-left: 155px;
    gap: 15px;
}

.submit-button {
    padding-top: 20px;
    padding-left: 150px;
}

#submit-button {
    border: none;
    border-color: black;
    border-spacing: 10px;
    padding: 10px;
    background-color: black;
    text-shadow: none;
    color: white;
    border-radius: 10px;
    height: 45px;
    width: 90px;
    font-size: 17px;
    font-weight: 400;
}

#submit-button:hover {
    cursor: pointer;
    background-color: rgb(18, 17, 17);
}

#subtitle5 {
    min-width: 236px;
    min-height:200px;
    font-weight: 600;
    padding-bottom: 4vh;
    border-radius: 10px;
    padding-left: 10px;
    padding-top: 10px;
    font-family: 'Satoshi', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

#date-picker {
    background-color: white;
}

#preferred {
    color: black;
    background-color: white;
}

#insurance {
    color: black;
    background-color: white;
}

#referred {
    color: black;
    background-color: white;
}

#subtitle5 {
    border-color: white;
    color: black;
}

#checkbox {
    width: 30px;
    height: 25px;
}


@media only screen and (max-width: 440px) {
    .body-appointment {
        padding: 3vh;
    }
    
    .body-header {
        display: flex;
        flex-direction: column;
        max-width: 200px;
    }

    .body-header1 {
        max-width: 280px;
        padding-bottom: 20px;
    }

    #subtitle {
        text-align: left;
    }

    .preferred {
        display: flex;
        flex-direction: column;
        align-items: self-start;
        max-width: 200px;
    }

    .body4 {
        max-width: 200px;
    }

    .personal {
        max-width: 200px;
    }

    .preferred2 {
        align-items: flex-start;
        margin-left: 0px;
        min-width: 300px;
    }

    .submit-button {
        padding-left: 0px;
    }

}