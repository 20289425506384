html {
    scroll-behavior: smooth;
   }

.tmj-page {
    padding-top: 20px;
    padding-left: 20vh;
    padding-right: 20vh;
    padding-bottom: 20vh;
    background-color:  rgb(248, 248, 248);
}

.nav-tmj {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

#tmj-buttons {
    display: flex;
    justify-content: space-around;
    border: none;
    border-color: black;
    border-spacing: 10px;
    padding: 10px;
    background-color: black;
    text-shadow: none;
    color: white;
    border-radius: 20px;
}

.accord {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* max-width: 500px; */
    padding-left: 20vh;
    padding-right: 20vh;
    padding-bottom: 20vh;
    gap: 40px;
    background-color:  rgb(248, 248, 248);
}

#tmj-paragraph {
    font-weight: 200;
    line-height: 2;
    padding-bottom: 20px;
}

#logo-subtitle2 {
    /* font-family: 'Literata', serif; */
    font-weight: 600;
    padding-top: 10px;
    
}

.header2 {
    background-color: black;
    background-image: url('../images/jkl.jpg');
    background-attachment: fixed;
    background-size: cover;
    padding-bottom: 50vh;
    position: relative;
    color: white;
}

#logo-subtitle2 {
    font-weight: 300;
    color: black;
}

#tmj-header1 {
    padding-bottom: 20px;
}

#tmj-header2 {
    padding-bottom: 20px;
}

#tmj-header3 {
    padding-bottom: 20px;
}

#tmj-header4 {
    padding-bottom: 20px;
}

#scroll-up-button {
    position: fixed; 
    width: 8%;
    left: 90%;
    bottom: 40px;
    height: 20px;
    font-size: 2.5rem;
    z-index: 1;
    cursor: pointer;
    color: #164F87;
}

@media only screen and (max-width: 440px) {
    .tmj-page {
        padding-left: 2vh;
        padding-right: 2vh;
    }

    .accord {
        padding: 3vh;
    }

    #scroll-up-button {
        position: fixed; 
        width: 5%;
        left: 85%;
        bottom: 40px;
        height: 20px;
        font-size: 2.5rem;
        z-index: 1;
        cursor: pointer;
        color: #164F87;
    }
}

@media only screen and (min-width: 1300px) {
    .tmj-page {
        padding-left: 25%;
        padding-right: 25%;

    }

    .accord {
        padding-left: 25%;
        padding-right: 25%;
    }
}