#iframe {
    width: 100%;
    height: 600px;
    
    /* frame-border : "0"; 
    scrolling: "no"; 
    margin-height: "0"; 
    margin-width: "0"; */
}

.iframe {
    width: 100%;
}