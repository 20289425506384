#drlee {
    width: 65vh;
    /* padding-right: 7vh; */
    border-radius: 10px;
}


#ourteam-title {
    color: #0C5EA4;
    padding-bottom: 20px;
}

.ourteam {
    display: flex;
    align-items: flex-start;
    padding-bottom: 200px;
    gap: 90px;
    background-color:  rgb(248, 248, 248);
}

/* .drlee {
    display: flex;
    justify-content: flex-start;
} */

.ourteam-pic {
    display: flex;
    justify-content: flex-start;
    padding-right: 7vh;
}

#about-drlee {
    font-weight: 200;
    line-height: 2;
    text-align: center;

}

.ourteam-paragraph {
    text-align: center;
    padding-left: 7vh;
}

.ourteam-button {
    border-color: black;
    border-spacing: 10px;
    padding: 10px;
    background-color: black;
    text-shadow: none;
    color: white;
    border-radius: 20px;
}

@media only screen and (max-width: 430px) {
    #drlee {
        max-width: 100%;
        border-radius: 0px;
    }
    .ourteam-paragraph {
        padding-left: 3vh;
        padding-right: 3vh;
    }

    .ourteam {
        padding-bottom: 0px;
        margin-bottom: -5px;
    }

    .ourteam-pic {
        padding-right: 0vh;
    }
}


@media only screen and (max-width: 1300px) {
    .ourteam {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ourteam-paragraph {
        padding-left: 30vh;
        padding-right: 30vh;
    }

    #drlee {
        padding-right: .000vh;
    }

}


@media only screen and (max-width: 1050px) {
    .ourteam-paragraph {
        padding-left: 10vh;
        padding-right: 10vh;
    }
}


@media only screen and (max-width: 750px) {
    .ourteam-paragraph {
        padding-left: 2vh;
        padding-right: 2vh;
    }
}