.sleep-container {
    display: flex;
    flex-direction: column;
    padding-left: 20vh;
    padding-right: 20vh;
    padding-bottom: 20vh;
    gap: 40px;
    background-color:  rgb(248, 248, 248);
}



.sleep-container2 {
    gap: 20px;
}

.nav-sleep {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.nav-sleep-section {
    gap: 20px;
}

#sleep-buttons {
    display: flex;
    justify-content: space-around;
    text-align: center;
    border: none;
    border-color: black;
    border-spacing: 10px;
    padding: 10px;
    background-color: black;
    text-shadow: none;
    color: white;
    border-radius: 20px;
}

.mouth-picture {
    display: flex;
    padding: 20px;
}

#mouth-picture {
    display: flex;
    width: auto;
    border-style:solid;
    border-radius: 20px;
}

#sleep-header1 {
    padding-top: 20px;
    padding-bottom: 20px;
}

#sleep-header2 {
    padding-bottom: 20px;
}

#sleep-header3 {
    padding-bottom: 20px;
}

#sleep-header4 {

    padding-bottom: 20px;
}

#sleep-header5 {
    padding-bottom: 20px;
}

#sleep-header6 {
    padding-bottom: 20px;
}

@media only screen and (max-width: 440px) {
    .sleep-container {
        padding-left: 2vh;
        padding-right: 2vh;
    }

    #mouth-picture {
        width: 300px;
    }

    #accord-title {
        font-size: 18px;
    }
}

@media only screen and (min-width: 1300px) {
    .sleep-container {
        padding-left: 25%;
        padding-right: 25%;

    }
}